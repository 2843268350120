<template>
  <b-modal
    id="myCustomModal"
    :title="crudmodaltitle"
    size="xlg"
    hide-footer
    scrollable
    centered
    button-size="lg"
  >
    <form
      id="formulaire"
      class="inherit"
      v-on:submit.prevent="onSubmit"
      :keydown="
        (e) => {
          e.preventDefault();
        }
      "
    >
      <div class="content-wrapper inherit">
        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="col-lg-12"></div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="date_vente_direct">Date Vente</label>
                  <input
                    type="text"
                    v-model="crudform.date_vente_direct_formatted"
                    class="form-control form-control-sm text-center"
                    readonly
                  />
                </div>
              </div>
              <div class="col-lg-6" v-if="typeShow=='Client'">
                <div class="form-group">
                  <label for="num_fa_client" >Numero Facture</label>
                  <input
                    type="text"
                    v-model="crudform.num_fa_client"
                    class="form-control form-control-sm text-center"
                    name="num_fa_client"
                    readonly
                  />
                </div>
              </div>
              <div class="col-lg-6" v-if="typeShow=='Fournisseur'">
                <div class="form-group" >
                  <label for="num_na_fournisseur">Numero Note d'achat</label>
                  <input
                    type="text"
                    v-model="crudform.num_na_fournisseur"
                    class="form-control form-control-sm text-center"
                    name="num_na_fournisseur"
                    readonly
                  />
                </div>
              </div>
              <div class="col-lg-12" v-if="showMagasinInfo">
                <div class="form-group">
                  <label for="">Magasin</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    :value="crudform.nom_magasin"
                    readonly
                  />
                </div>
              </div>
              <div class="col-lg-12" v-if="showMagasinInfo && typeShow=='Client'">
                <div class="card card-body bg-light">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>{{
                          $t("traitement.document_vente.modal.nom_client")
                        }}</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="crudform.nom_client"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>Adresse</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="crudform.adresse_client"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>E-mail</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="crudform.mail_client"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>Tel</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="crudform.tel_client"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>NIF</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="crudform.nif_client"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>Stat</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="crudform.stat_client"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>RCS</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="crudform.rcs_client"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>Quittance</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="crudform.quittance"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12" v-if="showMagasinInfo && typeShow=='Fournisseur'">
                <div class="card card-body bg-light">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>Nom du fournisseur</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="crudform.nom_fournisseur"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>Adresse</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="crudform.adresse_fournisseur"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>E-mail</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="crudform.mail_fournisseur"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>Tel</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="crudform.tel_fournisseur"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>NIF</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="crudform.nif_fournisseur"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>Stat</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="crudform.stat_fournisseur"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>RCS</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="crudform.rcs_fournisseur"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <label for="">{{
                    $t("traitement.document_vente.modal.comentaire")
                  }}</label>
                  <textarea
                    rows="3"
                    class="form-control form-control-sm"
                    name="commentaire"
                    v-model="crudform.commentaire"
                    readonly
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="">
              <label>{{
                $t("traitement.vente_fournisseur_client.modal.detail_vente")
              }}</label>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>
                      {{
                        $t(
                          "traitement.vente_fournisseur_client.modal.designation"
                        )
                      }}
                    </th>
                    <th>
                      {{
                        $t("traitement.vente_fournisseur_client.modal.quantite")
                      }}
                    </th>
                    <th v-if="typeShow=='Fournisseur'">
                      {{
                        $t("traitement.vente_fournisseur_client.modal.pu_frns")
                      }}
                    </th>
                    <th v-if="typeShow=='Fournisseur'">
                      {{
                        $t(
                          "traitement.vente_fournisseur_client.modal.remise_frns"
                        )
                      }}
                    </th>
                    <th v-if="typeShow=='Fournisseur'">
                      {{
                        $t(
                          "traitement.vente_fournisseur_client.modal.montant_frns"
                        )
                      }}
                    </th>
                    <th v-if="typeShow=='Client'">
                      {{
                        $t("traitement.vente_fournisseur_client.modal.pu_vente")
                      }}
                    </th>
                    <th v-if="typeShow=='Client'">
                      {{
                        $t(
                          "traitement.vente_fournisseur_client.modal.remise_vente"
                        )
                      }}
                    </th>
                    <th v-if="typeShow=='Client'">
                      {{
                        $t(
                          "traitement.vente_fournisseur_client.modal.montant_vente"
                        )
                      }}
                    </th>
                    <th width="40px">-</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(i, index) in crudform.details" :key="index">
                    <td>
                      <input
                        type="text"
                        name="designation[]"
                        class="form-control form-control-sm"
                        v-model="crudform.details[index].designation"
                        :title="crudform.details[index].designation"
                        readonly
                      />
                      <input
                        type="text"
                        name="articles_id[]"
                        class="d-none"
                        v-model="crudform.details[index].articles_id"
                      />
                    </td>
                    <td>
                      <vue-numeric
                        decimal-separator="."
                        currency=""
                        currency-symbol-position="suffix"
                        separator="space"
                        v-bind:precision="2"
                        v-model="crudform.details[index].quantite"
                        class="text-right form-control form-control-sm"
                        @input="updateMontant()"
                        readonly
                      ></vue-numeric>
                      <input
                        type="text"
                        name="quantite[]"
                        class="d-none"
                        v-model="crudform.details[index].quantite"
                      />
                    </td>
                    <td v-if="typeShow=='Fournisseur'">
                      <vue-numeric
                        decimal-separator="."
                        currency="Ar"
                        currency-symbol-position="suffix"
                        separator="space"
                        v-bind:precision="2"
                        v-model="crudform.details[index].pu_fournisseur"
                        class="text-right form-control form-control-sm"
                        @input="updateMontant()"
                        readonly
                      ></vue-numeric>
                      <input
                        type="text"
                        name="pu_fournisseur[]"
                        class="d-none"
                        v-model="crudform.details[index].pu_fournisseur"
                      />
                    </td>
                    <td v-if="typeShow=='Fournisseur'">
                      <vue-numeric
                        decimal-separator="."
                        currency="Ar"
                        currency-symbol-position="suffix"
                        separator="space"
                        v-bind:precision="2"
                        v-model="crudform.details[index].remise_fournisseur"
                        class="text-right form-control form-control-sm"
                        readonly
                        @input="updateMontant()"
                      ></vue-numeric>
                      <input
                        type="text"
                        name="remise_fournisseur[]"
                        class="d-none"
                        v-model="crudform.details[index].remise_fournisseur"
                      />
                    </td>
                    <td v-if="typeShow=='Fournisseur'">
                      <input
                        readonly
                        type="text"
                        class="text-right form-control form-control-sm"
                        :value="
                          new Intl.NumberFormat().format(
                            crudform.details[index].montant_fournisseur
                          ) + ' Ar'
                        "
                      />
                      <input
                        type="text"
                        name="montant_fournisseur[]"
                        class="d-none"
                        v-model="crudform.details[index].montant_fournisseur"
                      />
                    </td>
                    <td v-if="typeShow=='Client'">
                      <vue-numeric
                        decimal-separator="."
                        currency="Ar"
                        currency-symbol-position="suffix"
                        separator="space"
                        v-bind:precision="2"
                        v-model="crudform.details[index].pu_vente"
                        class="text-right form-control form-control-sm"
                        readonly
                        @input="updateMontant()"
                      ></vue-numeric>
                      <input
                        type="text"
                        name="pu_vente[]"
                        class="d-none"
                        v-model="crudform.details[index].pu_vente"
                      />
                    </td>
                    <td v-if="typeShow=='Client'">
                      <vue-numeric
                        decimal-separator="."
                        currency="Ar"
                        currency-symbol-position="suffix"
                        separator="space"
                        v-bind:precision="2"
                        v-model="crudform.details[index].remise_vente"
                        class="text-right form-control form-control-sm"
                        readonly
                        @input="updateMontant()"
                      ></vue-numeric>
                      <input
                        type="text"
                        name="remise_vente[]"
                        class="d-none"
                        v-model="crudform.details[index].remise_vente"
                      />
                    </td>
                    <td v-if="typeShow=='Client'">
                      <input
                        readonly
                        type="text"
                        class="text-right form-control form-control-sm"
                        :value="
                          new Intl.NumberFormat().format(
                            crudform.details[index].montant_vente
                          ) + ' Ar'
                        "
                      />
                      <input
                        type="text"
                        name="montant_vente[]"
                        class="d-none"
                        v-model="crudform.details[index].montant_vente"
                      />
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-danger rounded-0"
                        @click="delRow(index)"
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                  <tr v-if="crudform.details.length == 0">
                    <td class="text-center" colspan="9">
                      Aucune article n'a encore été choisit
                    </td>
                  </tr>
                </tbody>
                <tfoot v-if="crudform.details.length > 0">
                  <tr>
                    <td colspan="2" class="">Total montant sans remise</td>
                    <td colspan="3" v-if="typeShow=='Fournisseur'">
                      <input
                        :value="
                          new Intl.NumberFormat().format(
                            crudform.total_sans_remise_fournisseur
                          ) + ' Ar'
                        "
                        type="text"
                        name="total_sans_remise_fournisseur"
                        class="form-control form-control-sm text-right"
                        readonly
                      />
                    </td>
                    <td colspan="3" v-if="typeShow=='Client'">
                      <input
                        :value="
                          new Intl.NumberFormat().format(
                            crudform.total_sans_remise_vente
                          ) + ' Ar'
                        "
                        type="text"
                        name="total_sans_remise_vente"
                        class="form-control form-control-sm text-right"
                        readonly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="">Total montant avec remise</td>
                    <td colspan="3" v-if="typeShow=='Fournisseur'">
                      <input
                        :value="
                          new Intl.NumberFormat().format(
                            crudform.total_fournisseur
                          ) + ' Ar'
                        "
                        type="text"
                        name="total_fournisseur"
                        class="form-control form-control-sm text-right"
                        readonly
                      />
                    </td>
                    <td colspan="3" v-if="typeShow=='Client'">
                      <input
                        :value="
                          new Intl.NumberFormat().format(crudform.total_vente) +
                          ' Ar'
                        "
                        type="text"
                        name="total_vente"
                        class="form-control form-control-sm text-right"
                        readonly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="">Remise</td>
                    <td colspan="3" v-if="typeShow=='Fournisseur'">
                      <vue-numeric
                        decimal-separator="."
                        currency="Ar"
                        currency-symbol-position="suffix"
                        separator="space"
                        v-bind:precision="2"
                        v-model="crudform.remise_fournisseur"
                        class="text-right form-control form-control-sm"
                        readonly
                        @input="updateMontant()"
                      ></vue-numeric>
                      <input
                        type="text"
                        name="remise_parent_fournisseur"
                        class="d-none"
                        v-model="crudform.remise_fournisseur"
                      />
                    </td>
                    <td colspan="3" v-if="typeShow=='Client'">
                      <vue-numeric
                        decimal-separator="."
                        currency="Ar"
                        currency-symbol-position="suffix"
                        separator="space"
                        v-bind:precision="2"
                        v-model="crudform.remise_vente"
                        class="text-right form-control form-control-sm"
                        readonly
                        @input="updateMontant()"
                      ></vue-numeric>
                      <input
                        type="text"
                        name="remise_parent_vente"
                        class="d-none"
                        v-model="crudform.remise_vente"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="">Net à payer</td>
                    <td colspan="3" v-if="typeShow=='Fournisseur'">
                      <input
                        :value="
                          new Intl.NumberFormat().format(
                            crudform.net_a_payer_fournisseur
                          ) + ' Ar'
                        "
                        type="text"
                        name="net_a_payer_fournisseur"
                        class="form-control form-control-sm text-right"
                        readonly
                      />
                    </td>
                    <td colspan="3" v-if="typeShow=='Client'">
                      <input
                        :value="
                          new Intl.NumberFormat().format(
                            crudform.net_a_payer_vente
                          ) + ' Ar'
                        "
                        type="text"
                        name="net_a_payer_vente"
                        class="form-control form-control-sm text-right"
                        readonly
                      />
                    </td>
                  </tr>
                </tfoot>
              </table>
              <table class="table table-bordered mt-2">
                <thead>
                  <tr>
                    <td colspan="8" class="text-center">
                      {{ $t("historique_reglement.historique_paiement") }}
                      <span>
                        <span class="text-danger"
                          >N°{{ crudform.num_facture }}</span
                        ></span
                      >
                    </td>
                  </tr>
                  <tr>
                    <th class="text-center">
                      {{ $t("historique_reglement.date") }}
                    </th>
                    <th>{{ $t("historique_reglement.montant") }}</th>
                    <th>{{ $t("historique_reglement.reference") }}</th>
                    <th class="text-center">
                      {{ $t("historique_reglement.mode_paiement") }}
                    </th>
                    <!-- <th>Piece Jointe</th> -->
                    <th>{{ $t("historique_reglement.remarque") }}</th>
                    <th class="text-center">
                      {{ $t("historique_reglement.date_enregistrement") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="r in listdata.reglements" :key="r.id">
                    <td class="text-center vuetable-slot">
                      {{ r.date_reglement_formatted }}
                    </td>
                    <td class="text-right text-bold vuetable-slot">
                      <vue-numeric
                        decimal-separator="."
                        currency="Ar"
                        separator="space"
                        v-bind:precision="2"
                        read-only
                        currency-symbol-position="suffix"
                        empty-value="0"
                        v-model="r.montant"
                      ></vue-numeric>
                    </td>
                    <td class="vuetable-slot">{{ r.reference }}</td>
                    <td class="vuetable-slot text-center">
                      {{ r.mode_paiement }}
                    </td>
                    <!-- <td>{{ r.piece_jointe }}</td> -->
                    <td class=""><span v-html="r.remarques"></span></td>
                    <td class="vuetable-slot text-center">
                      {{ r.date_heure_enregistrement }}
                    </td>
                  </tr>

                  <tr v-if="listdata.reglements.length == 0">
                    <td colspan="8" class="text-center">
                      Aucun paiement n'a encore été effectué
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <input
        type="hidden"
        v-model="crudform.id"
        class="form-control"
        id="id"
        placeholder="id"
        name="id"
      />
      <input type="hidden" v-model="crudform.from_bl" name="from_bl" />
      <input
        v-for="id in crudform.bon_livraison_id"
        :key="id"
        type="hidden"
        :value="id"
        name="bon_livraison_id[]"
      />

      <input
        type="hidden"
        name="mode_paiement"
        v-model="crudform.mode_paiement"
      />
      <input type="hidden" name="remarques" v-model="crudform.remarques" />
      <input type="hidden" name="reference" v-model="crudform.reference" />
      <input
        type="hidden"
        name="date_echeance"
        v-model="crudform.date_echeance"
      />
      <input type="hidden" v-model="has_echeance" name="has_echeance" />
      <div class="myCustomModal modal-footer">
        <b-button variant="secondary" @click="closeModal()">{{
          $t("general.fermer")
        }}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import axios from "axios"

// import moment from "moment"
export default {
  name: "Ventedirectmodal",
  data() {
    return {
      datepicker: {
        format: "DD/MM/YYYY",
        useCurrent: false,
      },
      crudform: {
        id: "",
        num_vente_direct: "",
        date_vente_direct_formatted: "",
        clients_id: "",
        fournisseurs_id: "",
        magasin_id: "",
        commentaire: "",
        total_tva: "",
        total_ht: "",
        total_ttc: "",
        total_remise: "",
        total_a_payer: "",
        details: [],
      },
      crudmodaltitle: this.$t(
        "traitement.document_vente.modal.nouveau_facture"
      ),
      point_de_ventes: [],
      point_de_ventes_id: "",
      isReadonly: false,
      isShowButton: true,
      users_id: "",
      avec_degats: false,
      vente_en_gros: false,
      has_echeance_facture: false,
      has_echeance: false,
      listdata: {
        reglements: [],
      },
      typeShow: "Client"
    };
  },
  props: {
    showMagasinInfo: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  methods: {
    openModal() {
      this.$bvModal.show("myCustomModal");
    },
    closeModal() {
      this.$bvModal.hide("myCustomModal");
    },
    delRow: function (i) {
      this.crudform.details.splice(i, 1);
      this.updateMontant();
    },
    updateMontant: function () {
      this.crudform.total_sans_remise_fournisseur = 0;
      this.crudform.total_fournisseur = 0;
      this.crudform.net_a_payer_fournisseur = 0;
      this.crudform.total_sans_remise_vente = 0;
      this.crudform.total_vente = 0;
      this.crudform.net_a_payer_vente = 0;

      for (var i = 0; i < this.crudform.details.length; i++) {
        if (
          this.crudform.details[i].pu_vente == null ||
          this.crudform.details[i].pu_vente == ""
        ) {
          this.crudform.details[i].pu_vente = 0;
        }
        if (
          this.crudform.details[i].pu_fournisseur == null ||
          this.crudform.details[i].pu_fournisseur == ""
        ) {
          this.crudform.details[i].pu_fournisseur = 0;
        }
        if (
          this.crudform.details[i].quantite == null ||
          this.crudform.details[i].quantite == ""
        ) {
          this.crudform.details[i].quantite = 0;
        }
        if (
          this.crudform.details[i].remise_fournisseur == "" ||
          this.crudform.details[i].remise_fournisseur == null
        ) {
          this.crudform.details[i].remise_fournisseur = 0;
        }
        if (
          this.crudform.details[i].remise_vente == "" ||
          this.crudform.details[i].remise_vente == null
        ) {
          this.crudform.details[i].remise_vente = 0;
        }

        this.crudform.details[i].montant_fournisseur_sans_remise =
          parseFloat(this.crudform.details[i].pu_fournisseur) *
          parseFloat(this.crudform.details[i].quantite);
        this.crudform.details[i].montant_fournisseur =
          this.crudform.details[i].montant_fournisseur_sans_remise -
          this.crudform.details[i].remise_fournisseur;
        this.crudform.details[i].montant_vente_sans_remise =
          parseFloat(this.crudform.details[i].pu_vente) *
          parseFloat(this.crudform.details[i].quantite);
        this.crudform.details[i].montant_vente =
          this.crudform.details[i].montant_vente_sans_remise -
          this.crudform.details[i].remise_vente;

        // Mettre a jour les totaux
        this.crudform.total_sans_remise_fournisseur +=
          this.crudform.details[i].montant_fournisseur_sans_remise;
        this.crudform.total_fournisseur +=
          this.crudform.details[i].montant_fournisseur;
        this.crudform.total_sans_remise_vente +=
          this.crudform.details[i].montant_vente_sans_remise;
        this.crudform.total_vente += this.crudform.details[i].montant_vente;
      }

      this.crudform.net_a_payer_fournisseur =
        this.crudform.total_fournisseur - this.crudform.remise_fournisseur;
      this.crudform.net_a_payer_vente =
        this.crudform.total_vente - this.crudform.remise_vente;
    },
    fetchreglements: function (id_vente_direct) {
      this.listdata.reglements = [];
      var lien = "";

      if (this.typeShow == "Fournisseur") {
        lien =
          this.BASE_URL + "/ventedirectreglement/fetchna/" + id_vente_direct;
      }
      if (this.typeShow == "Client") {
        lien =
          this.BASE_URL + "/ventedirectreglement/fetchfa/" + id_vente_direct;
      }

      axios.get(lien).then((response) => {
        this.listdata.reglements = response.data;
      });

    },
    editRow(rowData,type) {
      this.crudmodaltitle = "Information sur : ";
      this.typeShow = type;
      //alert("You clicked edit on" + JSON.stringify(rowData))
      axios
        .get(this.BASE_URL + "/ventedirect/get/" + rowData.id)
        .then((response) => {
          //console.log(response.data[0]);
          if (this.typeShow == "Client") {
            this.crudmodaltitle +=
              " FACTURE N°" + response.data[0].num_fa_client;
          } else if (this.typeShow == "Fournisseur") {
            this.crudmodaltitle +=
              " NOTE D'ACHAT N°" + response.data[0].num_na_fournisseur;
          }

          this.crudform = response.data[0];
          console.log(this.crudform);
        });
      this.fetchreglements(rowData.id);
      this.openModal();
    },
  },

  mounted() {
    console.log(this.showMagasinInfo);
  },
};
</script>
